import { JobStatus, Patient } from '@doc-abode/data-models';
import { FormikValues } from 'formik';
import moment from 'moment';
import { FC } from 'react';

import { getStringEndTime } from '../../../../../helpers';
import { shouldShowPostJobNotes } from '../../../../../helpers/shouldShowPostJobNotes';
import getHcpName from '../../../../../helpers/ucr/getHcpName';
import { transformFormikValuesToPatientForWarningValidation } from '../../../../../helpers/ucr/getWarnings';
import { IconChangeStatus } from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';
import { DialogAlerts, Dialogs } from '../../../../../stores/UCRStore';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import {
    Accordion,
    AccordionColors,
    AccordionTab,
    Button,
    ButtonElems,
    ButtonSizes,
} from '../../../../v2/components';
import { Text } from '../../../../v2/form';
import WarningMessagesPanel from '../../../../v2/form/WarningMessagesPanel';
import { TimeHelperActualEnd, TimeHelperActualStart } from '../../blocks/panels/TimeHelper';
import { usePatientFlagsToChangeStatus } from '../../hooks/usePatientFlagsToChangeStatus';
import { useWarningMessageViewModel } from '../../hooks/useWarningMessageViewModel';
import { FormMode, FormSteps } from '../common';
import PatientDetailsReviewForm from '../PatientDetails/PatientDetailsReviewForm';

interface Props {
    values: FormikValues;
    onSave: (values: FormikValues) => Promise<void>;
    onEdit: (step: FormSteps) => void;
    formMode: string;
    setVisitData: (values: FormikValues | null) => void;
}

const ReviewForm: FC<Props> = ({ values, onSave, onEdit, formMode, setVisitData }) => {
    const {
        RootStore: {
            usersStore: { users },
            ucrStore: { openedDialogAlert, setOpenedDialogAlert, setOpenedDialog, jobs },
        },
    } = useStores<{ RootStore: RootStore }>();

    const patient: Patient = jobs.filter((user: any) => user.id === values.id)[0];
    const { isDisabled } = usePatientFlagsToChangeStatus(patient);

    if (
        openedDialogAlert !== DialogAlerts.SAVE_ADMINISTRATIVE_TIME &&
        formMode !== FormMode.EDIT_VISIT
    ) {
        setOpenedDialogAlert(DialogAlerts.SAVE_ADMINISTRATIVE_TIME);
    }

    // Wrapping in an array to keep changes to downstream dependencies minimal
    const staffFiltered = [getHcpName(users, values.hcpId, false)];

    // Create a temporary job to allow reuse of validations
    const tmpJobForValidation = transformFormikValuesToPatientForWarningValidation({
        values,
        isAdmin: true,
    });

    const { warningMessages, hasWarningMessages } = useWarningMessageViewModel({
        job: tmpJobForValidation,
    });

    return (
        <Accordion>
            <AccordionTab
                name="patiens"
                title="Patient details"
                onEdit={() => onEdit(FormSteps.PATIENT)}
                color={AccordionColors.PINK}
                open
            >
                <PatientDetailsReviewForm values={values} />
            </AccordionTab>
            <AccordionTab
                name="activity"
                title="Activity details"
                onEdit={() => onEdit(FormSteps.ACTIVITY)}
                color={AccordionColors.PINK}
                open
            >
                <CareDetails values={values} staffFiltered={staffFiltered} patient={patient} />
            </AccordionTab>
            <ConditionalDisplay show={hasWarningMessages}>
                <div className="v2__form-group--pos-1-1">
                    <WarningMessagesPanel warnings={warningMessages} />
                </div>
            </ConditionalDisplay>
            {formMode !== FormMode.EDIT_VISIT && (
                <div>
                    <label className="v2__form-group">
                        <Button
                            className="v2__form-submit-button"
                            elem={ButtonElems.DIV}
                            name={'Add administrative time'}
                            size={ButtonSizes.MEDIUM}
                            clickEvent={() => onSave(values)}
                        />
                    </label>
                </div>
            )}

            {isDisabled ? (
                <Button
                    className="v2__form-submit-button"
                    size={ButtonSizes.MEDIUM}
                    name="Change status"
                    Icon={IconChangeStatus}
                    clickEvent={() => setOpenedDialog(Dialogs.CHANGE_VISIT_STATUS)}
                />
            ) : null}
        </Accordion>
    );
};

export default ReviewForm;

function CareDetails({
    values,
    staffFiltered,
    patient,
}: {
    values: FormikValues;
    staffFiltered: string[];
    patient: Patient;
}) {
    const jobStatus: JobStatus = values.jobStatus;
    const showPatientNotes = shouldShowPostJobNotes({ jobStatus });

    return (
        <div className="v2__form-block">
            <Text
                name="activityType"
                title="Activity"
                description={values.activityType}
                className="v2__form-group--pos-1-1"
            />
            <Text
                name="Notes"
                title="Pre-job notes"
                className="visit-details__notes v2__form-group--pos-1-1"
                description={values.notes}
            />
            <Text
                name="visitDate"
                title="Planned date of job"
                description={moment(values.visitDate).format('dddd Do MMMM YYYY')}
                className="v2__form-group--pos-1-4"
            />
            <Text
                name="startTime"
                title="Planned start time"
                description={values.startTime ? moment(values.startTime).format('hh:mm a') : ''}
                className="v2__form-group--pos-2-4"
            />
            <Text
                name="duration"
                title="Planned duration"
                description={values.duration}
                className="v2__form-group--pos-3-4"
            />
            <Text
                name="endTime"
                title="Expected end time"
                description={getStringEndTime(
                    values.startTime || values.startDateTime,
                    values.duration,
                    'hh:mm a',
                )}
                className="v2__form-group--pos-4-4"
            />

            <Text
                name="staffRequired"
                title="Staffing requirements"
                description={staffFiltered}
                className="v2__form-group--pos-1-2"
            />
            <Text
                name="arrivedDateTime"
                title="Actual start time"
                notSetText="Not yet recorded"
                description={TimeHelperActualStart({ patient: patient, isFirstUser: true })}
                className="v2__form-group--pos-1-2"
            />
            <Text
                name="finishedDateTime"
                title="Actual end time"
                notSetText="Not yet recorded"
                description={TimeHelperActualEnd({ patient: patient, isFirstUser: true })}
                className="v2__form-group--pos-2-2"
            />
            <ConditionalDisplay show={showPatientNotes}>
                <Text
                    name="postVisitNotes"
                    title="Post-job notes"
                    className="visit-details__notes"
                    description={values.postVisitNotes}
                />
            </ConditionalDisplay>
        </div>
    );
}
