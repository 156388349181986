import { DispositionType } from '@doc-abode/data-models';
import { FieldArray, FormikContextType, FormikValues, useFormikContext } from 'formik';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';

import { dateFormat } from '../../../../../constants/patientsConst';
import { getMomentDateFormatter } from '../../../../../helpers/ucr';
import { transformFormikValuesToPatientForWarningValidation } from '../../../../../helpers/ucr/getWarnings';
import useStores from '../../../../../hook/useStores';
import useVisitWarnings from '../../../../../hook/useVisitWarnings';
import RootStore from '../../../../../stores/RootStore';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Button, ButtonColors, ButtonSizes } from '../../../../v2/components';
import {
    AutocompletePlacesInput,
    DateInput,
    MultiSelect,
    PhoneInput,
    Place,
    Select,
    TextInput,
} from '../../../../v2/form';
import WarningMessagesPanel from '../../../../v2/form/WarningMessagesPanel';
import { VisitValuesType } from '../../blocks/panels/VisitDetailsTypes';
import { useWarningMessageViewModel } from '../../hooks/useWarningMessageViewModel';
import { FormMode, FormSteps, getButtonName } from '../common';

interface PatientDetailsFormProps {
    loading: boolean;
    formMode: FormMode;
    // gender?: Gender[];
    languagesSpoken?: any;
    gender?: any;
    genderStaffPreferred?: any;
    onNextStep?: () => void;
    onSaveForm?: (curStep: FormSteps) => void;
    onSubmitAction?: () => void;
    handleChangePatientRequired?: (isRequired: boolean) => void;
    required?: boolean;
}

export const PatientDetailsForm: FC<PatientDetailsFormProps> = ({
    loading,
    formMode,
    gender,
    languagesSpoken,
    genderStaffPreferred,
    onNextStep = () => {},
    onSaveForm = () => {},
    onSubmitAction = () => {},
    handleChangePatientRequired,
    required = true,
}) => {
    const {
        RootStore: {
            ucrStore: { updateWarnings },
            configStore: { clientKeys },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { errors, setFieldValue, values }: FormikContextType<FormikValues> = useFormikContext();

    const [isContinueBtnClicked, setIsContinueBtnClicked] = useState(false);
    const MAXDATE = moment().startOf('day').toDate();
    const MINDATE = new Date(1900, 1, 1);

    const noErrors = () => {
        const patientErrors = [
            errors?.additionalContactNumbers,
            errors?.firstName,
            errors?.lastName,
            errors?.contactNumber,
            errors?.gender,
            errors?.nhsNumber,
            errors?.postCode,
            errors?.town,
            errors?.addressLine1,
            errors?.dateOfBirth,
        ].some((error) => !error);
        return patientErrors;
    };

    const onSubmit = () => {
        if (noErrors()) {
            if (formMode === FormMode.EDIT_VISIT) {
                onSaveForm(FormSteps.PATIENT);
            } else {
                onNextStep();
            }
        } else {
            setIsContinueBtnClicked(true);
            onSubmitAction?.();
        }
    };

    const onPlaceSelected = ({
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        postcode,
    }: Place) => {
        setFieldValue('addressLine1', addressLine1 || '');
        setFieldValue('town', city || '');
        setFieldValue('postCode', postcode || '');
        setFieldValue('addressLine2', addressLine2 || '');
        setFieldValue('addressLine3', addressLine3 || '');
    };

    useEffect(() => {
        handleChangePatientRequired?.(required);
    }, [required, handleChangePatientRequired]);

    // Update (BE) warnings for the current job
    const visitWarnings = useVisitWarnings(values as VisitValuesType);

    useEffect(() => {
        updateWarnings(values.id, visitWarnings || []);
    }, [
        values.id,
        values.nhsNumber,
        values.visitDate,
        values.startTime,
        values.duration,
        visitWarnings,
        updateWarnings,
    ]);

    // Determine warning messages to show
    const tmpJob = transformFormikValuesToPatientForWarningValidation({
        values,
        isAdmin: values.disposition === DispositionType.ADMIN,
    });

    const { warningMessages, hasWarningMessages } = useWarningMessageViewModel({
        job: tmpJob,
        sectionShown: FormSteps.PATIENT,
    });

    return (
        <>
            <div className="v2__form-block">
                <TextInput
                    name="firstName"
                    className="v2__form-group--pos-1-3"
                    label="First name"
                    required={required}
                    disabled={loading}
                    isContinueBtnClicked={isContinueBtnClicked}
                />
                <TextInput
                    name="middleName"
                    className="v2__form-group--pos-2-3"
                    label="Middle name(s)"
                    disabled={loading}
                />
                <TextInput
                    name="lastName"
                    className="v2__form-group--pos-3-3"
                    label="Last name"
                    required={required}
                    disabled={loading}
                    isContinueBtnClicked={isContinueBtnClicked}
                />
                <FieldArray
                    name="additionalContactNumbers"
                    render={(arrayHelpers: any) => (
                        <>
                            <PhoneInput
                                className="v2__form-group--pos-1-2"
                                name="contactNumber"
                                label="Phone number(s)"
                                disabled={loading}
                                isContinueBtnClicked={
                                    isContinueBtnClicked && values.additionalContactNumbers
                                }
                            />
                            {!values.additionalContactNumbers?.length && (
                                <div className="v2__form-group--pos-2-2 v2__form-add-main-numbers-block ">
                                    <Button
                                        className=" v2__form-add-numbers-button"
                                        type="submit"
                                        name={'+'}
                                        size={ButtonSizes.SMALL}
                                        color={ButtonColors.GREY}
                                        clickEvent={() => arrayHelpers.push('')}
                                    ></Button>
                                </div>
                            )}

                            {values.additionalContactNumbers?.length
                                ? values.additionalContactNumbers.map(
                                      (additionalNumber: string, index: number) => (
                                          <>
                                              <PhoneInput
                                                  className="v2__form-group--pos-1-2"
                                                  name={`additionalContactNumbers.${index}`}
                                                  disabled={loading}
                                              />
                                              {values.additionalContactNumbers.length - 1 !==
                                                  index && (
                                                  <div className="v2__form-group--pos-2-2 v2__form-add-numbers-block">
                                                      <Button
                                                          className="v2__form-remove-number-single-button"
                                                          type="submit"
                                                          name={'-'}
                                                          size={ButtonSizes.SMALL}
                                                          clickEvent={() =>
                                                              arrayHelpers.remove(index)
                                                          }
                                                      ></Button>
                                                  </div>
                                              )}
                                              {values.additionalContactNumbers.length - 1 ===
                                                  index && (
                                                  <div className="v2__form-group--pos-2-2 v2__form-add-numbers-block">
                                                      <Button
                                                          className=" v2__form-add-numbers-button"
                                                          type="submit"
                                                          name={'+'}
                                                          size={ButtonSizes.SMALL}
                                                          color={ButtonColors.GREY}
                                                          clickEvent={() => arrayHelpers.push('')}
                                                      ></Button>

                                                      <Button
                                                          className="v2__form-remove-numbers-button"
                                                          type="submit"
                                                          name={'-'}
                                                          size={ButtonSizes.SMALL}
                                                          clickEvent={() =>
                                                              arrayHelpers.remove(index)
                                                          }
                                                      ></Button>
                                                  </div>
                                              )}
                                          </>
                                      ),
                                  )
                                : null}
                        </>
                    )}
                />

                <DateInput
                    name="dateOfBirth"
                    className="v2__form-group--pos-1-2"
                    label="Date of birth"
                    {...getMomentDateFormatter(dateFormat)}
                    dateFormat={dateFormat}
                    onError={() => {
                        // nasty hack
                        // if we leave the outOfRangeMessages invalidDateMessage in the input box the
                        // date can be submitted by default,
                        // by setting the value to previousDate
                        // and modifying the minuts a tiny bit we get a new date value but as this is date of birth
                        // the bit we want - the date will remain unchanged, so an update will be triggers
                        // restoring to the previous value
                        // this mimics the pre upgrade to blueprintjs5 behaviour
                        // if there is a way to avoid the outOfRangeMessage / invalidDateMessage
                        // i could not find it.
                        // another issue is that as there is an error the current value remains, so we cannot do
                        // checkDateForError(values.dateOfBirth) and then make our own error.
                        const previousDate = values.dateOfBirth;

                        let to = 1;
                        if (previousDate.getMinutes() === 1) {
                            to = 0;
                        }
                        previousDate.setMinutes(to);
                        setFieldValue('dateOfBirth', previousDate);
                    }}
                    required={required}
                    disabled={loading}
                    maxDate={MAXDATE}
                    minDate={MINDATE}
                />
                <Select
                    name="gender"
                    className="v2__form-group--pos-2-2"
                    label="Gender"
                    required={required}
                    options={gender}
                    disabled={loading}
                    isContinueBtnClicked={isContinueBtnClicked}
                />
            </div>
            <div className="v2__form-block">
                {clientKeys.googleMaps.length > 0 && (
                    <AutocompletePlacesInput
                        name="addressLine1"
                        aria-label="address line 1"
                        className="v2__form-group--pos-1-2"
                        label="Address line 1"
                        required={required}
                        disabled={loading}
                        isContinueBtnClicked={isContinueBtnClicked}
                        onPlaceSelected={onPlaceSelected}
                        apiKey={clientKeys.googleMaps}
                    />
                )}
                <TextInput
                    name="addressLine2"
                    className="v2__form-group--pos-2-2"
                    label="Address line 2"
                    disabled={loading}
                />
                <TextInput
                    name="addressLine3"
                    className="v2__form-group--pos-1-2"
                    label="Address line 3"
                    disabled={loading}
                />
                <TextInput
                    name="town"
                    aria-label="town"
                    className="v2__form-group--pos-2-2"
                    label="Town/City"
                    required={required}
                    disabled={loading}
                    isContinueBtnClicked={isContinueBtnClicked}
                />
                <TextInput
                    name="postCode"
                    aria-label="postcode"
                    className="v2__form-group--pos-1-2"
                    label="Postcode"
                    required={required}
                    disabled={loading}
                    isContinueBtnClicked={isContinueBtnClicked}
                />
            </div>
            <div className="v2__form-block">
                <h2 className="v2__form-group--pos-1-2">Patient preference</h2>
                <MultiSelect
                    name="languagesSpoken"
                    className="v2__form-group--pos-1-2"
                    label="Spoken language"
                    options={languagesSpoken}
                    withAllOption={false}
                    optionClassName="v2__form-multi-select-option"
                />
                <MultiSelect
                    name="staffPreferredGender"
                    className="v2__form-group--pos-2-2"
                    label="Gender of visiting staff"
                    options={genderStaffPreferred}
                    singleSelectOption={{
                        value: 'nopreference',
                        label: 'No preference',
                    }}
                    withAllOption={false}
                    optionClassName="v2__form-multi-select-option"
                />
            </div>
            <div className="v2__form-block">
                <ConditionalDisplay show={hasWarningMessages}>
                    <div className="v2__form-group--pos-1-1">
                        <WarningMessagesPanel warnings={warningMessages} />
                    </div>
                </ConditionalDisplay>
                <label className="v2__form-group">
                    <Button
                        className="v2__form-submit-button"
                        name={getButtonName(formMode)}
                        size={ButtonSizes.MEDIUM}
                        clickEvent={onSubmit}
                    />
                </label>
            </div>
        </>
    );
};
