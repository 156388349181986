import {
    filterDuplicateWarningMessages,
    transformBackendWarnings,
} from '../../../helpers/ucr/getWarnings';
import useStores from '../../../hook/useStores';
import { WarningType } from '../../../interfaces/ucr';
import RootStore from '../../../stores/RootStore';
import WarningMessagesPanel from './WarningMessagesPanel';

type WarningsProps = {
    warnings?: WarningType[];
    stepWarnings?: Array<string>;
    spacerTop?: boolean;
};

const Warnings = ({ warnings = [], stepWarnings, spacerTop = true }: WarningsProps) => {
    const {
        RootStore: {
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    // Future work to be done - concatenation of different warnings into a single warning type so we can start standardising
    let combinedWarnings: WarningType[] = [];

    // Add the job specific warnings that have been determined elsewhere
    // Future work - redesign how these warnings are populated
    const jobSpecificWarnings = transformBackendWarnings({
        warnings,
        users,
    });

    combinedWarnings.push(...jobSpecificWarnings);

    if (stepWarnings) {
        stepWarnings.forEach((warning) =>
            combinedWarnings.push({ category: warning, message: warning }),
        );
    }

    combinedWarnings = filterDuplicateWarningMessages({
        warnings: combinedWarnings,
    });

    return <WarningMessagesPanel warnings={combinedWarnings} spacerTop={spacerTop} />;
};

export default Warnings;
