import { FormikValues } from 'formik';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { dateFormat } from '../../../../../constants/patientsConst';
import { getMomentDateFormatter } from '../../../../../helpers/ucr';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { Button, ButtonSizes } from '../../../../v2/components';
import {
    Checkbox,
    DateInput,
    DurationInput,
    Select,
    TextArea,
    TimeInput,
} from '../../../../v2/form';
import WarningMessagesPanel from '../../../../v2/form/WarningMessagesPanel';
import { VisitValuesType } from '../../blocks/panels/VisitDetailsTypes';
import { HcpSelect } from '../../components';
import { FormMode, FormSteps } from '../common';
import { useActivityDetailsViewModel } from './useActivityDetailsViewModel';

export type ActivityDetailsType = {
    values: FormikValues;
    loading: boolean;
    onNextStep: (next: FormSteps, prev: FormSteps) => void;
    setFieldValue: (field: string, value: any) => void;
    formMode: FormMode;
    onSaveForm?: (values: FormikValues) => Promise<void>;
};

const ActivityDetails: FC<ActivityDetailsType> = ({
    values,
    loading,
    onNextStep,
    setFieldValue,
    formMode,
}) => {
    const {
        setShowUnavailableHcp,
        onSaveClick,
        buttonName,
        defaultStartTime,
        finalOptions,
        hasWarningMessages,
        highlightedWarnings,
        isContinueBtnClicked,
        MAXDATE,
        MINTIME,
        showPatientNotes,
        showUnavailableHcp,
        warningMessages,
    } = useActivityDetailsViewModel({
        values,
        loading,
        onNextStep,
        setFieldValue,
        formMode,
    });
    return (
        <div className="v2__form-block">
            <Select
                name="activityType"
                className="v2__form-group--pos-1-2"
                label="Activity"
                required
                disabled={loading}
                isContinueBtnClicked={isContinueBtnClicked}
            >
                {finalOptions}
            </Select>
            <TextArea
                name="notes"
                className="v2__form-group--pos-1-1"
                label="Pre-job notes"
                disabled={loading}
            />
            <DateInput
                name="visitDate"
                className="v2__form-group--pos-1-4"
                label="Planned date of job"
                {...getMomentDateFormatter(dateFormat)}
                dateFormat={dateFormat}
                required
                disabled={loading}
                displayErrors={highlightedWarnings['visitDate']}
                maxDate={MAXDATE}
                minDate={MINTIME}
                isContinueBtnClicked={isContinueBtnClicked}
            />
            <TimeInput
                name="startTime"
                className="v2__form-group--pos-2-4"
                label="Planned start time"
                defaultValue={values.startTime || defaultStartTime}
                selectAllOnFocus
                disabled={loading}
                required
                displayErrors={highlightedWarnings['startTime']}
            />
            <DurationInput
                name="duration"
                className="v2__form-group--pos-3-4"
                label="Planned duration"
                selectAllOnFocus
                disabled={loading}
                required
            />
            <TimeInput
                name="endTime"
                className="v2__form-group--pos-4-4"
                label="Expected end time"
                disabled
            />
            <Checkbox
                name="Show unavailable"
                className="v2__form-group--pos-1-2"
                label="Staff member"
                title="Show unavailable"
                disabled={loading}
                checked={showUnavailableHcp}
                onChange={() => setShowUnavailableHcp(!showUnavailableHcp)}
                aria-label="show unavailable"
            />
            <HcpSelect
                patient={values as VisitValuesType}
                disabled={loading}
                includeUnavailableHcps={showUnavailableHcp}
                ariaLabel="staff select"
            />
            <ConditionalDisplay show={showPatientNotes}>
                <TextArea
                    name="postVisitNotes"
                    className="v2__form-group--pos-1-1"
                    label="Post-job notes"
                    disabled={loading}
                />
            </ConditionalDisplay>
            <ConditionalDisplay show={hasWarningMessages}>
                <div className="v2__form-group--pos-1-1">
                    <WarningMessagesPanel warnings={warningMessages} />
                </div>
            </ConditionalDisplay>
            <label className="v2__form-group">
                <Button
                    className="v2__form-submit-button"
                    name={buttonName}
                    size={ButtonSizes.MEDIUM}
                    clickEvent={onSaveClick}
                    type="submit"
                />
            </label>
        </div>
    );
};

export default observer(ActivityDetails);
