import {
    Alert,
    Button,
    Checkbox,
    FormGroup,
    Icon,
    InputGroup,
    Switch,
    Tab,
    Tabs,
    Tooltip,
} from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import Select from 'react-select';

import { genderMapping, getDisplayBand } from '../../../../constants/mainConst';
import { getHcpTypesDisplayLabel } from '../../../../helpers/getHcpTypeDisplayLabel';
import { getHcpName } from '../../../../helpers/ucr';
import { getHcp } from '../../../../helpers/ucr/getHcp';
import { TooltipsAccessRights } from '../../../../interfaces/admin';
import Error from '../../forms/Error';
import Warning from '../../forms/Warning';
import { formatDisplayDateTime } from '../../helpers/formatData';
import AppToaster from '../../helpers/Toaster';
import Modal from '../../modal/Modal';

const AdminUserDetails = inject('RootStore')(
    observer(
        class AdminUserDetails extends Component {
            state = {
                saving: false,
                enabled: this.props.enabled,
                isEdit: false,
                isOpenConfirm: false,
                band: '',
                gender: '',
                phoneNumber: '',
                email: '',
                languages: [],
                roles: [],
                rolesInDB: [],
                errors: {
                    gender: [],
                    languages: [],
                    roles: [],
                    s1Username: [],
                },
                warnings: [],
                isValid: true,
                showResetPasswordDialog: false,
                userSession: {},
                accountType: '',
                organisation: '',
                s1Username: '',
            };

            componentDidMount() {
                const { userId } = this.props.match.params;
                const { users } = this.props.RootStore.usersStore;

                const user = getHcp(users, userId);

                this._handleSetEnabled(user.enabled);

                this._handleInitialState(user);

                this._getUserSession();
            }

            _getUserSession = async (user) => {
                const userSession = await this.props.RootStore.userStore.getUserSession();
                this.setState({ userSession });
            };

            _handleSetEnabled = (enabled) => {
                this.setState({ enabled });
            };

            _handleInitialState = (user) => {
                const gender = this.props.RootStore.lovsStore.gender.find(
                    (g) => g.value === user.gender,
                );
                const { bands } = this.props.RootStore.configStore;
                let band = bands.find((g) => g.value === user.band);
                let phoneNumber;
                if (user.phoneNumber) {
                    phoneNumber = user.phoneNumber.replace(/\s/g, '');
                    if (phoneNumber.startsWith('0')) {
                        phoneNumber = phoneNumber.substring(1);
                    }
                    if (phoneNumber.startsWith('+')) {
                        phoneNumber = phoneNumber.substring(1);
                    }
                    if (!phoneNumber.startsWith('44')) {
                        phoneNumber = `44${phoneNumber}`;
                    }
                }

                this.setState({
                    band,
                    gender,
                    languages: (user.preferences?.languages || []).map((item) => ({
                        label: item,
                        value: item,
                    })),
                    phoneNumber,
                    email: user.email,
                    roles: user.roles || [],
                    rolesInDB: user.roles || [],
                    accountType: user.accountType,
                    organisation: user.organisation,
                    errors: {
                        gender: [],
                        languages: [],
                        roles: [],
                        s1Username: [],
                    },
                    warnings: [],
                    s1Username: user.s1Username,
                });
            };

            _handleCloseClick = () => {
                this.props.history.push(`/users`);
            };

            _toggleIsEdit = () => {
                const { userId } = this.props.match.params;
                const { users } = this.props.RootStore.usersStore;

                const user = users.find((user) => user.userId === userId);

                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });

                this._handleInitialState(user);
            };

            _setFieldValue = (field, value) => {
                this.setState({
                    [field]: value,
                });
            };

            _handleSaveDemographics = async (user) => {
                // Despite phone number no longer being editable
                // leaving it in the update request to prevent accidental nullifying of the data
                const { gender, languages, enabled, phoneNumber, email, band, s1Username } =
                    this.state;
                const rolesData = this._getRolesData();

                if (!gender || !gender.value) {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            gender: ['This field is required.'],
                        },
                    });
                    return null;
                }

                const isValid = this._validateFields(user);

                if (!isValid) {
                    return null;
                }

                this.setState({ saving: true });

                const languagesData = languages.map((l) => l.value);
                const demographicsData = {
                    gender: gender.value,
                    phoneNumber,
                    email,
                    orgData: {
                        preferences: {
                            ...user.preferences,
                            languages: languagesData,
                        },
                        band: band ? band.value : '',
                        enabled,
                    },
                    rolesData,
                    s1Username,
                };

                const response = await this.props.RootStore.usersStore.setUserDemographics(
                    user.userId,
                    demographicsData,
                );

                if (response.successful) {
                    AppToaster.show({
                        message: 'User successfully updated.',
                        intent: 'success',
                    });

                    this.setState({ isEdit: false });
                } else {
                    AppToaster.show({
                        message: 'Unable to update user, please try again.',
                        intent: 'danger',
                    });
                }

                this.setState({ saving: false });
            };

            _validateFields = (currentUser, field) => {
                const { roles } = this.state;
                const warnings = [];
                let roleErrors = [];
                let isValid = true;

                if (roles && ((field && field === 'roles') || !field)) {
                    if (
                        !roles.some((g) =>
                            [
                                'controller',
                                `controller-${currentUser.organisation}`,
                                'org_admin',
                                `org_admin-${currentUser.organisation}`,
                                'hcp',
                            ].includes(g),
                        )
                    ) {
                        roleErrors.push('Please select at least one role.');
                        isValid = false;
                    }
                }

                this.setState({
                    errors: {
                        ...this.state.errors,
                        roles: roleErrors,
                    },
                    warnings: warnings,
                    isValid: isValid,
                });

                return isValid;
            };

            _handleResetPassword = async (user) => {
                this.setState({ saving: true });

                const response = await this.props.RootStore.usersStore.resetPassword(user.userId);

                if (response.successful) {
                    AppToaster.show({
                        message: "User's password succesfully reset.",
                        intent: 'success',
                    });

                    this.setState({ isEdit: false });
                } else {
                    AppToaster.show({
                        message: "Unable to update user's password, please try again.",
                        intent: 'danger',
                    });
                }

                this.setState({ saving: false, showResetPasswordDialog: false });
            };

            _handleRolesChange = (role, user) => {
                const { roles } = this.state;
                if (roles.find((g) => g === role)) {
                    this.setState(
                        {
                            roles: roles.filter((g) => g !== role),
                        },
                        () => this._validateFields(user, 'roles'),
                    );
                } else {
                    this.setState(
                        {
                            roles: [...roles, role],
                        },
                        () => this._validateFields(user, 'roles'),
                    );
                }
            };

            _getRolesData = () => {
                const { roles, rolesInDB } = this.state;
                const add = [];
                const remove = [];
                rolesInDB.forEach((g) => {
                    if (!roles.includes(g)) {
                        remove.push(`${g}-${this.state.organisation}`);
                    }
                });
                roles.forEach((g) => {
                    if (!rolesInDB.includes(g)) {
                        add.push(`${g}-${this.state.organisation}`);
                    }
                });
                return { add, remove };
            };

            _renderUserSummary = (user) => {
                const {
                    userId,
                    email,
                    preferences: { languages } = {},
                    gender,
                    phoneNumber,
                    lastUpdatedBy,
                    lastUpdatedAt,
                    s1Username,
                } = user;
                const { enabled, isEdit } = this.state;
                const {
                    usersStore: { users, getUserHcpTypes },
                    configStore: { isFeatureEnabled },
                } = this.props.RootStore;
                const { bands } = this.props.RootStore.configStore;
                const labelBand = this.state.band && this.state.band.label;

                const notificationsEnabled = user.available ? 'on' : 'off';

                return (
                    <div>
                        <dl className="info">
                            <dt className="info__title">Notifications</dt>
                            <dd className="info__definition">
                                <span
                                    className={`users-table__notifications users-table__notifications--${notificationsEnabled}`}
                                >
                                    <Icon icon={user.available ? 'tick-circle' : 'ban-circle'} />
                                    <span className="users-list__status-label">
                                        {notificationsEnabled}
                                    </span>
                                </span>
                            </dd>
                            <dt className="info__title">HCP Type(s)</dt>
                            <dd className="info__definition">
                                {getHcpTypesDisplayLabel(getUserHcpTypes(user.userId))}
                            </dd>
                            <dt className="info__title">User ID</dt>
                            <dd className="info__definition">{userId}</dd>
                            <dt className="info__title">Phone Number</dt>
                            <dd className="info__definition">
                                {phoneNumber && phoneNumber.startsWith('44')
                                    ? `+${phoneNumber}`
                                    : phoneNumber}
                            </dd>
                            <dt className="info__title">Email Address</dt>
                            <dd className="info__definition">{email}</dd>
                            <dt className="info__title">Enabled</dt>
                            <dd className="info__definition">
                                <Switch
                                    checked={enabled}
                                    large
                                    disabled={!isEdit}
                                    onChange={() => this._handleSetEnabled(!enabled)}
                                />
                            </dd>
                            <dt className="info__title">Roles</dt>
                            <dd className="info__definition">
                                <FormGroup labelFor="roles" className="v2__form-group">
                                    <Tooltip
                                        popoverClassName={'ucr-listview__popover'}
                                        content={TooltipsAccessRights.HCP_MOBILE}
                                        disabled={!isEdit}
                                    >
                                        <Checkbox
                                            checked={this.state.roles.includes('hcp')}
                                            disabled={!isEdit}
                                            onChange={() => this._handleRolesChange('hcp', user)}
                                            inline
                                        >
                                            Mobile
                                        </Checkbox>
                                    </Tooltip>
                                    <Tooltip
                                        popoverClassName={'ucr-listview__popover'}
                                        content={TooltipsAccessRights.CONTROLLER}
                                        disabled={!isEdit}
                                    >
                                        <Checkbox
                                            checked={this.state.roles.includes('controller')}
                                            disabled={!isEdit}
                                            onChange={() =>
                                                this._handleRolesChange('controller', user)
                                            }
                                            inline
                                        >
                                            Controller
                                        </Checkbox>
                                    </Tooltip>
                                    <Tooltip
                                        popoverClassName={'ucr-listview__popover'}
                                        content={TooltipsAccessRights.ADMIN}
                                        disabled={!isEdit}
                                    >
                                        <Checkbox
                                            checked={this.state.roles.includes('org_admin')}
                                            disabled={
                                                !isEdit ||
                                                user.userId === this.state.userSession.username
                                            }
                                            onChange={() =>
                                                this._handleRolesChange('org_admin', user)
                                            }
                                            inline
                                        >
                                            Admin
                                        </Checkbox>
                                    </Tooltip>
                                    <Tooltip
                                        popoverClassName={'ucr-listview__popover'}
                                        content={TooltipsAccessRights.SUPERUSER}
                                        disabled={!isEdit}
                                    >
                                        <Checkbox
                                            checked={this.state.roles.includes('superuser')}
                                            disabled={!isEdit}
                                            onChange={() =>
                                                this._handleRolesChange('superuser', user)
                                            }
                                            inline
                                        >
                                            Superuser
                                        </Checkbox>
                                    </Tooltip>
                                    <Error errors={this.state.errors.roles} />
                                </FormGroup>
                            </dd>
                            <dt className="info__title">Maximum drive time</dt>
                            <dd className="info__definition">
                                {user.maxDriveTimeMinutes
                                    ? `${user.maxDriveTimeMinutes} mins`
                                    : '-'}
                            </dd>
                            <dt className="info__title">Specialities</dt>
                            <dd className="info__definition">
                                {user.preferences?.specialities &&
                                user.preferences.specialities.length > 0
                                    ? user.preferences.specialities.join(', ')
                                    : '-'}
                            </dd>
                        </dl>
                        <div>
                            <h3 className="h3">Demographics</h3>
                            <dl className="info">
                                <dt className="info__title">
                                    Gender <span className="required-asterisk">*</span>
                                </dt>
                                {isEdit ? (
                                    <dd className="info__definition">
                                        <FormGroup label="" labelFor="gender">
                                            <Select
                                                inputId="gender"
                                                options={this.props.RootStore.lovsStore.gender}
                                                value={this.state.gender}
                                                onChange={(e) => this._setFieldValue('gender', e)}
                                            />
                                            <Error errors={this.state.errors.gender} />
                                        </FormGroup>
                                    </dd>
                                ) : (
                                    <dd className="info__definition">
                                        {gender ? genderMapping[gender] : 'Not set'}
                                    </dd>
                                )}
                                <dt className="info__title">Languages</dt>
                                {isEdit ? (
                                    <dd className="info__definition">
                                        <FormGroup label="" labelFor="languages">
                                            <Select
                                                inputId="languages"
                                                options={
                                                    this.props.RootStore.lovsStore.languagesSpoken
                                                }
                                                value={this.state.languages}
                                                onChange={(e) =>
                                                    this._setFieldValue('languages', e)
                                                }
                                                isMulti
                                                className="react-select"
                                            />
                                            <Error errors={this.state.errors.languages} />
                                        </FormGroup>
                                    </dd>
                                ) : (
                                    <dd className="info__definition">
                                        {languages?.length > 0 ? languages.join(', ') : 'Not set'}
                                    </dd>
                                )}
                                <dt className="info__title">Last changed on</dt>
                                <dd className="info__definition">
                                    {lastUpdatedAt ? formatDisplayDateTime(lastUpdatedAt) : null}
                                </dd>
                                <dt className="info__title">Last changed by</dt>
                                <dd className="info__definition">
                                    {getHcpName(users, lastUpdatedBy)}
                                </dd>
                            </dl>
                        </div>
                        <div>
                            <h3 className="h3">Professional details</h3>
                            <dl className="info">
                                <dt className="info__title">Band</dt>
                                {isEdit ? (
                                    <dd className="info__definition">
                                        <FormGroup labelFor="band">
                                            <Select
                                                inputId="band"
                                                options={bands
                                                    ?.slice()
                                                    .sort((a, b) => a.label.localeCompare(b.label))}
                                                menuPlacement="top"
                                                isClearable
                                                value={this.state.band}
                                                onChange={(e) => this._setFieldValue('band', e)}
                                                className="react-select"
                                            />
                                        </FormGroup>
                                    </dd>
                                ) : (
                                    <dd className="info__definition">
                                        {labelBand ? getDisplayBand(labelBand) : 'Not set'}
                                    </dd>
                                )}
                                {isFeatureEnabled('s1Enabled') && (
                                    <>
                                        <dt className="info__title">SystmOne Username</dt>
                                        {isEdit ? (
                                            <dd className="info__definition">
                                                <FormGroup labelFor="s1Username">
                                                    <InputGroup
                                                        id="s1Username"
                                                        large
                                                        onChange={(e) =>
                                                            this._setFieldValue(
                                                                's1Username',
                                                                e.target.value.trim(),
                                                            )
                                                        }
                                                        onBlur={(e) =>
                                                            this._validateFields(user, 's1Username')
                                                        }
                                                        value={this.state.s1Username}
                                                    />
                                                    <Error errors={this.state.errors.s1Username} />
                                                </FormGroup>
                                            </dd>
                                        ) : (
                                            <dd className="info__definition">
                                                {s1Username ? s1Username : 'Not set'}
                                            </dd>
                                        )}
                                    </>
                                )}
                            </dl>
                        </div>
                        {isEdit && <Warning warnings={this.state.warnings} />}
                    </div>
                );
            };

            _renderFooter = (user) => {
                const { saving, isEdit } = this.state;
                const {
                    configStore: { isFeatureEnabled },
                    userStore: { isAdmin },
                } = this.props.RootStore;

                const handleSave = () => {
                    this._handleSaveDemographics(user);
                };

                const s1UsernameNotProvided =
                    !this.state.s1Username || this.state.s1Username === '';

                if (!isAdmin) {
                    return <></>;
                }

                return (
                    <>
                        {isEdit ? (
                            <>
                                <Button
                                    text="Cancel"
                                    icon="cross"
                                    loading={saving}
                                    onClick={this._toggleIsEdit}
                                    large
                                    outlined
                                />
                                <Button
                                    text="Save changes"
                                    intent="success"
                                    icon="tick"
                                    loading={saving}
                                    onClick={() => {
                                        if (
                                            isFeatureEnabled('s1Enabled') &&
                                            s1UsernameNotProvided
                                        ) {
                                            this.setState({ isOpenConfirm: true });
                                        } else {
                                            handleSave();
                                        }
                                    }}
                                    disabled={!this.state.isValid}
                                    large
                                    outlined
                                />
                                <Alert
                                    isOpen={this.state.isOpenConfirm}
                                    onConfirm={() => {
                                        this.setState({ isOpenConfirm: false });
                                        handleSave();
                                    }}
                                    onCancel={() => this.setState({ isOpenConfirm: false })}
                                    cancelButtonText="Cancel"
                                    confirmButtonText="Confirm"
                                    icon="warning-sign"
                                    intent="primary"
                                    className="dialog--wide"
                                >
                                    <p>
                                        Because you have not provided a SystmOne username, Doc Abode
                                        will not be able to manage diary entries on SystmOne on
                                        behalf of the health care professional.
                                    </p>
                                </Alert>
                            </>
                        ) : (
                            <>
                                <Button
                                    text="Edit"
                                    intent="primary"
                                    icon="edit"
                                    onClick={this._toggleIsEdit}
                                    large
                                    outlined
                                />
                                <Button
                                    text="Reset password"
                                    intent="danger"
                                    icon="key"
                                    onClick={() => this.setState({ showResetPasswordDialog: true })}
                                    large
                                    outlined
                                />
                            </>
                        )}
                    </>
                );
            };

            render() {
                const {
                    RootStore: {
                        usersStore: { users },
                    },
                    match: {
                        params: { userId },
                    },
                } = this.props;

                const { showResetPasswordDialog, saving } = this.state;

                const user = users.find((user) => user.userId === userId);

                return (
                    <Modal
                        title={user.userName}
                        onClose={this._handleCloseClick}
                        shadow
                        footer={this._renderFooter(user)}
                    >
                        <Tabs id="TabsExample" defaultSelectedTabId="summary" large>
                            <Tab
                                id="summary"
                                title="Summary"
                                panel={this._renderUserSummary(user)}
                            />
                        </Tabs>
                        <Alert
                            isOpen={showResetPasswordDialog}
                            onConfirm={() => this._handleResetPassword(user)}
                            onCancel={() => this.setState({ showResetPasswordDialog: false })}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            icon="warning-sign"
                            intent="danger"
                            loading={saving}
                        >
                            <p>Are you sure you want to reset this user's password?</p>
                        </Alert>
                    </Modal>
                );
            }
        },
    ),
);

export default AdminUserDetails;
