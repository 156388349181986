/**
 *
 * SimpleWarning is a hardcoded based warning so it relevantly dumb you pass a warning=[{value: }]
 */

import { FC } from 'react';

import { IconWarning } from '../../../helpers/ucr/icons';
import { Callout } from '../components/Callout';

interface SimpleWarningInterface {
    warnings: String[];
    spacerTop?: boolean;
    spacerBottom?: boolean;
}
export const SimpleWarning: FC<SimpleWarningInterface> = ({
    spacerBottom,
    spacerTop,
    warnings,
}) => {
    return (
        <Callout
            intent="warning"
            Icon={IconWarning}
            spacerBottom={spacerBottom || false}
            spacerTop={spacerTop || false}
        >
            {warnings.map((value, index) => {
                return (
                    <p className="warning-block" key={index + 'warning'}>
                        {value}
                    </p>
                );
            })}
        </Callout>
    );
};
